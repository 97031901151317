import Head from "next/head";
import NextLink from "next/link";

import { useRouter } from "next/router";

import { useSession } from "next-auth/react";

import { Box, Card, Container, Typography } from "@mui/material";
import { JWTLogin } from "../../components/authentication/jwt-login";
import { Logo } from "../../components/logo";

import { useSettings } from "../../hooks/use-settings";

const Login = () => {
    const { settings } = useSettings();
    const router = useRouter();
    const { data: session } = useSession();

    if (session) router.push("/dashboard");

    return (
        <>
            <Head>
                <title>Login | TCA Developments</title>
            </Head>
            <Box
                component="main"
                sx={{
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        py: {
                            xs: "60px",
                            md: "120px",
                        },
                    }}
                >
                    <Card elevation={16} sx={{ p: 4 }}>
                        <Box
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <NextLink href="/dashboard" passHref>
                                <a>
                                    <Logo
                                        showdark={String(
                                            settings.theme === "light"
                                        )}
                                        width="120px"
                                        height="90px"
                                    />
                                </a>
                            </NextLink>
                            <Typography
                                color="body1"
                                sx={{ mt: 2 }}
                                variant="body2"
                            >
                                Login with your company credentials
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                mt: 3,
                            }}
                        >
                            <JWTLogin />
                            <Box sx={{ mt: 3 }}>
                                <NextLink href="/authentication/password-recovery">
                                    Forget your password?
                                </NextLink>
                            </Box>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

Login.getLayout = (page) => <div>{page}</div>;

// this is the only public page in the app
Login.public = true;

export default Login;
