import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormHelperText, TextField } from "@mui/material";

export const JWTLogin = () => {
    const router = useRouter();

    const formik = useFormik({
        initialValues: {
            username: "",
            // email: "",
            password: "",
            submit: null,
        },
        validationSchema: Yup.object({
            username: Yup.string().max(255).required("Username is required"),
            // email: Yup.string()
            // 	.email("Must be a valid email")
            // 	.max(255)
            // 	.required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
        }),
        onSubmit: async (values, helpers) => {
            try {
                const res = await signIn("credentials", {
                    redirect: false,
                    callbackUrl: "/dashboard",
                    username: values.username,
                    password: values.password,
                });

                if (res.error !== null) throw new Error(res.error);

                // redirect to home page
                router.push(res.url);
            } catch (err) {
                console.log(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        },
    });

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            {/* <TextField
				autoFocus
				error={Boolean(formik.touched.email && formik.errors.email)}
				fullWidth
				helperText={formik.touched.email && formik.errors.email}
				label="Email Address"
				margin="normal"
				name="email"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				type="email"
				value={formik.values.email}
			/> */}
            <TextField
                error={Boolean(
                    formik.touched.username && formik.errors.username
                )}
                fullWidth
                helperText={formik.touched.username && formik.errors.username}
                label="Username"
                margin="normal"
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="username"
                value={formik.values.username}
            />
            <TextField
                error={Boolean(
                    formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
            />
            {formik.errors.submit && (
                <Box sx={{ mt: 3 }}>
                    <FormHelperText error>
                        {formik.errors.submit}
                    </FormHelperText>
                </Box>
            )}
            <Box sx={{ mt: 2 }}>
                <Button
                    disabled={formik.isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                >
                    Log In
                </Button>
            </Box>
        </form>
    );
};
